import { DragDropModule } from '@angular/cdk/drag-drop';
import { OverlayModule } from '@angular/cdk/overlay';
import { ScrollingModule } from '@angular/cdk/scrolling';
import { CommonModule } from '@angular/common';
import { HttpClientModule } from '@angular/common/http';
import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MatAutocompleteModule } from '@angular/material/autocomplete';
import { MatButtonModule } from '@angular/material/button';
import { MatButtonToggleModule } from '@angular/material/button-toggle';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { MatChipsModule } from '@angular/material/chips';
import { MatNativeDateModule } from '@angular/material/core';
import { MatDatepickerModule } from '@angular/material/datepicker';
import { MatIconModule } from '@angular/material/icon';
import { MatInputModule } from '@angular/material/input';
import { MatMenuModule } from '@angular/material/menu';
import { MatProgressBarModule } from '@angular/material/progress-bar';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { MatRadioModule } from '@angular/material/radio';
import { MatSelectModule } from '@angular/material/select';
import { MatSlideToggleModule } from '@angular/material/slide-toggle';
import { MatSliderModule } from '@angular/material/slider';
import { MatSnackBarModule } from '@angular/material/snack-bar';
import { MatTooltipModule } from '@angular/material/tooltip';
import { RouterModule } from '@angular/router';
import { GridsterModule } from 'angular2gridster';

/* Third Party */
import { TranslateModule } from '@ngx-translate/core';
import { CanvasWhiteboardModule } from '@pk-solutions/ng2-canvas-whiteboard';
import { NouisliderModule } from 'ng2-nouislider';
import { Ng2TelInputModule } from 'ng2-tel-input';
import { NgxSkeletonLoaderModule } from 'ngx-skeleton-loader';

/* Components */
import { ActionDropdownComponent } from './action-dropdown/action-dropdown.component';
import { AvatarEditorComponent } from './avatar/avatar-editor/avatar-editor.component';
import { AvatarPersonClusterComponent } from './avatar/avatar-person-cluster/avatar-person-cluster.component';
import { AvatarPersonComponent } from './avatar/avatar-person/avatar-person.component';
import { AvatarComponent } from './avatar/avatar.component';
import { ButtonGroupComponent } from './button-group/button-group.component';
import { ButtonListComponent } from './button-list/button-list.component';
import { CalloutComponent } from './callout/callout.component';
import { AccountCardComponent } from './card-select/account-card/account-card.component';
import { CardSelectComponent } from './card-select/card-select.component';
import { CompanyCardComponent } from './card-select/company-card/company-card.component';
import { EmailInviteCardComponent } from './card-select/email-invite-card/email-invite-card.component';
import { FacilityCardComponent } from './card-select/facility-card/facility-card.component';
import { ItemCardComponent } from './card-select/item-card/item-card.component';
import { PeopleCardComponent } from './card-select/people-card/people-card.component';
import { ChangeCompanyModalComponent } from './change-company-modal/change-company-modal.component';
import { ChangeCredentialsModalComponent } from './change-credentials-modal/change-credentials-modal.component';
import { ChartLegendComponent } from './chart-legend/chart-legend.component';
import { ChartTitleComponent } from './chart/chart-title/chart-title.component';
import { ChartComponent } from './chart/chart.component';
import { GeofenceTimeUtilizationChartComponent } from './chart/geofence-charts/geofence-time-utilization-chart/geofence-time-utilization-chart.component';
import { CheckboxComponent } from './checkbox/checkbox.component';
import { ChipListComponent } from './chip-list/chip-list.component';
import { ChipComponent } from './chip-list/chip/chip.component';
import { ClockComponent } from './clock/clock.component';
import { ColorPickerComponent } from './color-picker/color-picker.component';
import { CopyrightComponent } from './copyright/copyright.component';
import { DashboardVariableComponent } from './dashboard-variable/dashboard-variable.component';
import { DashboardFilterComponent } from './dashboard/dashboard-filter/dashboard-filter.component';
import { DashboardComponent } from './dashboard/dashboard.component';
import { DataFilterComponent } from './data-filter/data-filter.component';
import { FilterDropdownComponent } from './data-filter/filter-dropdown/filter-dropdown.component';
import { DatePickerComponent } from './date-picker/date-picker.component';
import { DateRangePickerHeaderNoArrowComponent } from './date-range-picker/date-range-picker-header/date-range-picker-header-no-arrow.component';
import { DateRangePickerHeaderComponent } from './date-range-picker/date-range-picker-header/date-range-picker-header.component';
import { DateRangePickerComponent } from './date-range-picker/date-range-picker.component';
import { DayTimePickerComponent } from './day-time-picker/day-time-picker.component';
import { DayTimeRangePickerComponent } from './day-time-range-picker/day-time-range-picker.component';
import { DeviceSettingsComponent } from './device-settings/device-settings.component';
import { DropdownComponent } from './dropdown/dropdown.component';
import { DvrControlsComponent } from './dvr-controls/dvr-controls.component';
import { EmailInputComponent } from './email-input/email-input.component';
import { EntityListComponent } from './entity-list/entity-list.component';
import { FacilityDropdownComponent } from './facility-dropdown/facility-dropdown.component';
import { FacilitySelectComponent } from './facility-select/facility-select.component';
import { FolderTreeSelectComponent } from './folder-tree-select/folder-tree-select.component';
import { FormButtonsComponent } from './form-buttons/form-buttons.component';
import { GasReadingIconDisplayComponent } from './gas-reading-icon-display/gas-reading-icon-display.component';
import { GasSensorReadingsDisplayComponent } from './gas-sensor-readings-display/gas-sensor-readings-display.component';
import { HideWindowControlComponent } from './hide-window-control/hide-window-control.component';
import { IconPickerComponent } from './icon-picker/icon-picker.component';
import { IconWithTextComponent } from './icon-with-text/icon-with-text.component';
import { IconComponent } from './icon/icon.component';
import { ImageFeedComponent } from './image-feed/image-feed.component';
import { ImagePreviewComponent } from './image-preview/image-preview.component';
import { ImageUploadComponent } from './image-upload/image-upload.component';
import { InputListComponent } from './input-list/input-list.component';
import { InputComponent } from './input/input.component';
import { LoaderComponent } from './loader/loader.component';
import { LoadingComponent } from './loading/loading.component';
import { LogoSpinnerComponent } from './logo-spinner/logo-spinner.component';
import { ModalComponent } from './modal/modal.component';
import { NetworkIndicatorComponent } from './network-indicator/network-indicator.component';
import { NoAccessComponent } from './no-access/no-access.component';
import { NotificationsComponent } from './notifications/notifications.component';
import { PassValidatorComponent } from './password/password.component';
import { PersonListComponent } from './person-select/person-list/person-list.component';
import { PersonSelectComponent } from './person-select/person-select.component';
import { PinInputComponent } from './pin-input/pin-input.component';
import { ProgressBarComponent } from './progress-bar/progress-bar.component';
import { PttModalComponent } from './ptt-modal/ptt-modal.component';
import { QrCodeComponent } from './qr-code/qr-code.component';
import { RangeSliderComponent } from './range-slider/range-slider.component';
import { RelativeDatePickerComponent } from './relative-date-picker/relative-date-picker.component';
import { ReportParametersComponent } from './report-parameters/report-parameters.component';
import { RightHeaderComponent } from './right-header/right-header.component';
import { SettingsMenuComponent } from './settings-menu/settings-menu.component';
import { GeofenceDetailComponent } from './simple-map/detail-views/geofence-detail/geofence-detail.component';
import { ItemDetailComponent } from './simple-map/detail-views/item-detail/item-detail.component';
import { PersonDetailComponent } from './simple-map/detail-views/person-detail/person-detail.component';
import { WaltDetailComponent } from './simple-map/detail-views/walt-detail/walt-detail.component';
import { SimpleMapSearchComponent } from './simple-map/simple-map-search/simple-map-search.component';
import { SimpleMapComponent } from './simple-map/simple-map.component';
import { TabBarComponent } from './tab-bar/tab-bar.component';
import { TabComponent } from './tab/tab.component';
import { FolderEditorModalComponent } from './table/folder-editor-modal/folder-editor-modal.component';
import { ParentEditFormComponent } from './table/parent-edit-form/parent-edit-form.component';
import { TableFilterRowComponent } from './table/table-filter-row/table-filter-row.component';
import { TableFilterComponent } from './table/table-filter/table-filter.component';
import { TableHeaderComponent } from './table/table-header/table-header.component';
import { TableRowComponent } from './table/table-row/table-row.component';
import { TableComponent } from './table/table.component';
import { TimePickerComponent } from './time-picker/time-picker.component';
import { TooltipComponent } from './tooltip/tooltip.component';
import { TreeExpansionMenuComponent } from './tree-expansion-menu/tree-expansion-menu.component';
import { UniversalDropdownComponent } from './universal-dropdown/universal-dropdown.component';
import { UploadButtonComponent } from './upload-button/upload-button.component';
import { VolumeBarComponent } from './volume-bar/volume-bar.component';
import { EventLogComponent } from './weavix-map/event-log/event-log.component';
import { MapControlComponent } from './weavix-map/map-control/map-control.component';
import { FilterHeaderComponent } from './weavix-map/weavix-map-filter/filter-header/filter-header.component';
import { FilterRowDisplayComponent } from './weavix-map/weavix-map-filter/filter-row-display/filter-row-display.component';
import { ItemRowDisplayComponent } from './weavix-map/weavix-map-filter/item-row-display/item-row-display.component';
import { PersonRowDisplayComponent } from './weavix-map/weavix-map-filter/person-row-display/person-row-display.component';
import { WeavixMapFilterComponent } from './weavix-map/weavix-map-filter/weavix-map-filter.component';
import { WidgetGridComponent } from './widget-grid/widget-grid.component';
import { ChartWidgetComponent } from './widgets/chart-widget/chart-widget.component';
import { CountComponent } from './widgets/count/count.component';
import { RichTextWidgetComponent } from './widgets/rich-text-widget/rich-text-widget.component';
import { TableWidgetComponent } from './widgets/table-widget/table-widget.component';
import { WidgetContainerComponent } from './widgets/widget-container/widget-container.component';
import { WeavixFormInputsModule } from '@weavix/components/src/weavix-form-inputs.module';

/* Directives */
import { ClickOutsideDirective } from 'weavix-shared/directives/click-outside.directive';
import { DigitOnlyDirective } from 'weavix-shared/directives/digit-only.directive';
import { PanDirective } from 'weavix-shared/directives/pan.directive';
import { PermissionDirective } from 'weavix-shared/directives/permission.directive';
import { PreventInputDirective } from 'weavix-shared/directives/prevent-input.directive';
import { ResizeDirective } from 'weavix-shared/directives/resize.directive';
import { ZoomDirective } from 'weavix-shared/directives/zoom.directive';
import { DragAndDropDirective } from './dnd.directive';

/* Pipes */
import { StatusHeaderComponent } from 'console/app/shared/status-header/status-header.component';
import { CommaNumberPipe } from 'weavix-shared/pipes/comma-number.pipe';
import { LinkifyPipe } from 'weavix-shared/pipes/linkify.pipe';
import { MaybeDecimalPipe } from 'weavix-shared/pipes/maybe-decimal.pipe';
import { ObjectKeysPipe } from 'weavix-shared/pipes/object-keys.pipe';
import { SafeHtmlPipe } from 'weavix-shared/pipes/safe-html.pipe';
import { ShortNumberPipe } from 'weavix-shared/pipes/short-number.pipe';
import { TimeAgoPipe } from 'weavix-shared/pipes/time-ago.pipe';
import { ReportComponent } from './report/report.component';
import { LocationDetailsComponent } from './simple-map/detail-views/location-details/location-details.component';
import { TimePickerDropdownComponent } from './time-picker-dropdown/time-picker-dropdown.component';


@NgModule({
    declarations: [
        AccountCardComponent,
        ActionDropdownComponent,
        AvatarComponent,
        AvatarPersonClusterComponent,
        AvatarPersonComponent,
        ButtonGroupComponent,
        ButtonListComponent,
        CalloutComponent,
        CardSelectComponent,
        ChartComponent,
        ChartLegendComponent,
        CheckboxComponent,
        ChipComponent,
        ChipListComponent,
        ClickOutsideDirective,
        ClockComponent,
        ColorPickerComponent,
        CommaNumberPipe,
        CompanyCardComponent,
        CopyrightComponent,
        DataFilterComponent,
        DatePickerComponent,
        DeviceSettingsComponent,
        DragAndDropDirective,
        DigitOnlyDirective,
        PreventInputDirective,
        DropdownComponent,
        DvrControlsComponent,
        EmailInputComponent,
        EmailInviteCardComponent,
        EventLogComponent,
        FacilityCardComponent,
        FacilityDropdownComponent,
        FacilitySelectComponent,
        FilterDropdownComponent,
        FilterHeaderComponent,
        FilterRowDisplayComponent,
        FolderEditorModalComponent,
        FolderTreeSelectComponent,
        FormButtonsComponent,
        GasReadingIconDisplayComponent,
        GasSensorReadingsDisplayComponent,
        GeofenceTimeUtilizationChartComponent,
        HideWindowControlComponent,
        IconComponent,
        IconPickerComponent,
        IconWithTextComponent,
        ImageFeedComponent,
        ImagePreviewComponent,
        ImageUploadComponent,
        InputComponent,
        ItemCardComponent,
        ItemDetailComponent,
        ItemRowDisplayComponent,
        LinkifyPipe,
        LoaderComponent,
        LoadingComponent,
        LogoSpinnerComponent,
        MapControlComponent,
        MaybeDecimalPipe,
        ModalComponent,
        NetworkIndicatorComponent,
        NoAccessComponent,
        NotificationsComponent,
        ParentEditFormComponent,
        PassValidatorComponent,
        PeopleCardComponent,
        PermissionDirective,
        PersonDetailComponent,
        PersonListComponent,
        PersonRowDisplayComponent,
        PersonSelectComponent,
        ProgressBarComponent,
        PttModalComponent,
        ReportComponent,
        RangeSliderComponent,
        RightHeaderComponent,
        SafeHtmlPipe,
        SettingsMenuComponent,
        ShortNumberPipe,
        SimpleMapComponent,
        StatusHeaderComponent,
        TabComponent,
        TabBarComponent,
        TableComponent,
        TooltipComponent,
        TableFilterComponent,
        TableFilterRowComponent,
        TableHeaderComponent,
        TableRowComponent,
        TimeAgoPipe,
        TreeExpansionMenuComponent,
        TreeExpansionMenuComponent,
        UniversalDropdownComponent,
        VolumeBarComponent,
        WeavixMapFilterComponent,
        UploadButtonComponent,
        DateRangePickerComponent,
        DateRangePickerHeaderComponent,
        DateRangePickerHeaderNoArrowComponent,
        ZoomDirective,
        PanDirective,
        ResizeDirective,
        ChartTitleComponent,
        TimePickerComponent,
        TimePickerDropdownComponent,
        InputListComponent,
        DashboardVariableComponent,
        ReportParametersComponent,
        RelativeDatePickerComponent,
        WidgetGridComponent,
        WidgetContainerComponent,
        CountComponent,
        RichTextWidgetComponent,
        TableWidgetComponent,
        SimpleMapSearchComponent,
        EntityListComponent,
        GeofenceDetailComponent,
        DashboardComponent,
        DashboardFilterComponent,
        ObjectKeysPipe,
        ChartWidgetComponent,
        DayTimePickerComponent,
        DayTimeRangePickerComponent,
        PinInputComponent,
        WaltDetailComponent,
        AvatarEditorComponent,
        ChangeCompanyModalComponent,
        ChangeCredentialsModalComponent,
        QrCodeComponent,
        LocationDetailsComponent,
    ],
    imports: [
        CommonModule,
        DragDropModule,
        FormsModule,
        GridsterModule.forRoot(),
        HttpClientModule,
        CanvasWhiteboardModule,
        MatAutocompleteModule,
        MatButtonModule,
        MatButtonToggleModule,
        MatCheckboxModule,
        MatChipsModule,
        MatDatepickerModule,
        MatIconModule,
        MatInputModule,
        MatNativeDateModule,
        MatProgressBarModule,
        MatProgressSpinnerModule,
        MatRadioModule,
        MatSelectModule,
        MatSliderModule,
        MatSlideToggleModule,
        MatSnackBarModule,
        MatTooltipModule,
        Ng2TelInputModule,
        NgxSkeletonLoaderModule,
        NouisliderModule,
        ReactiveFormsModule,
        RouterModule,
        ScrollingModule,
        TranslateModule,
        MatMenuModule,
        WeavixFormInputsModule,
    ],
    exports: [
        AccountCardComponent,
        ActionDropdownComponent,
        AvatarComponent,
        AvatarPersonClusterComponent,
        AvatarPersonComponent,
        ButtonGroupComponent,
        ButtonListComponent,
        CalloutComponent,
        CanvasWhiteboardModule,
        CardSelectComponent,
        ChartComponent,
        ChartLegendComponent,
        CheckboxComponent,
        ChipComponent,
        ChipListComponent,
        ClickOutsideDirective,
        ClockComponent,
        ColorPickerComponent,
        CommaNumberPipe,
        CompanyCardComponent,
        CopyrightComponent,
        DataFilterComponent,
        DatePickerComponent,
        DateRangePickerComponent,
        DeviceSettingsComponent,
        DragAndDropDirective,
        DragDropModule,
        DropdownComponent,
        DvrControlsComponent,
        EmailInputComponent,
        EventLogComponent,
        FacilityDropdownComponent,
        FacilitySelectComponent,
        FilterDropdownComponent,
        FilterHeaderComponent,
        FilterRowDisplayComponent,
        FolderEditorModalComponent,
        FolderTreeSelectComponent,
        FormButtonsComponent,
        FormsModule,
        GasReadingIconDisplayComponent,
        GasSensorReadingsDisplayComponent,
        GeofenceTimeUtilizationChartComponent,
        HideWindowControlComponent,
        IconComponent,
        IconPickerComponent,
        IconWithTextComponent,
        ImageFeedComponent,
        ImagePreviewComponent,
        InputComponent,
        ItemCardComponent,
        ItemDetailComponent,
        ItemRowDisplayComponent,
        LinkifyPipe,
        LoaderComponent,
        LoadingComponent,
        LogoSpinnerComponent,
        MapControlComponent,
        MatAutocompleteModule,
        MatButtonModule,
        MatButtonToggleModule,
        MatCheckboxModule,
        MatChipsModule,
        MatDatepickerModule,
        MatIconModule,
        MatInputModule,
        MatNativeDateModule,
        MatProgressBarModule,
        MatProgressSpinnerModule,
        MatRadioModule,
        MatSelectModule,
        MatSliderModule,
        MatSlideToggleModule,
        MatSnackBarModule,
        MatTooltipModule,
        MatMenuModule,
        MaybeDecimalPipe,
        ModalComponent,
        NetworkIndicatorComponent,
        Ng2TelInputModule,
        NgxSkeletonLoaderModule,
        NoAccessComponent,
        NotificationsComponent,
        OverlayModule,
        ParentEditFormComponent,
        PassValidatorComponent,
        PermissionDirective,
        PersonDetailComponent,
        PersonRowDisplayComponent,
        PersonSelectComponent,
        ProgressBarComponent,
        PttModalComponent,
        ReportComponent,
        ReactiveFormsModule,
        RangeSliderComponent,
        RightHeaderComponent,
        SafeHtmlPipe,
        ScrollingModule,
        SettingsMenuComponent,
        ShortNumberPipe,
        SimpleMapComponent,
        StatusHeaderComponent,
        TabComponent,
        TabBarComponent,
        TableComponent,
        TooltipComponent,
        EntityListComponent,
        SimpleMapSearchComponent,
        TableFilterComponent,
        TableFilterRowComponent,
        TableHeaderComponent,
        TableRowComponent,
        TimeAgoPipe,
        TranslateModule,
        TreeExpansionMenuComponent,
        UniversalDropdownComponent,
        VolumeBarComponent,
        WeavixMapFilterComponent,
        UploadButtonComponent,
        ZoomDirective,
        PanDirective,
        ResizeDirective,
        ChartTitleComponent,
        TimePickerComponent,
        TimePickerDropdownComponent,
        InputListComponent,
        DashboardVariableComponent,
        ReportParametersComponent,
        RelativeDatePickerComponent,
        WidgetGridComponent,
        DashboardComponent,
        DashboardFilterComponent,
        ObjectKeysPipe,
        ChartWidgetComponent,
        PinInputComponent,
        AvatarEditorComponent,
        ChangeCompanyModalComponent,
        ChangeCredentialsModalComponent,
        QrCodeComponent,
        WeavixFormInputsModule,
    ],
})
export class SharedModule { }
